import React from "react"
import Styleguide from "../components/Styleguide/index"
import AllIcons from "../components/Icon/icons"

function Styles() {
  return (
    <>
      <AllIcons />
      <Styleguide />
    </>
  )
}

export default Styles
