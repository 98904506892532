import React from "react"
import LinkCustom from "../LinkCustom"

import typeStyles from "./typography.module.scss"
import styles from "./links.module.scss"

const list = [
  {
    appearance: "large",
    scope: "internal",
    content: "Poppins Regular <br />31/50 spacing: -1",
  },
  {
    appearance: "small",
    scope: "internal",
    content: "Poppins Regular <br />20/30 spacing: -1",
  },
  {
    appearance: "nav",
    scope: "internal",
    content: "Poppins Semibold 13/13",
  },
  {
    appearance: "large",
    scope: "external",
    content: "Poppins Regular <br />31/50 spacing: -1",
  },
  {
    appearance: "small",
    scope: "external",
    content: "Poppins Regular <br />20/30 spacing: -1",
  },
  {
    appearance: "nav",
    scope: "external",
    content: "Poppins Semibold 13/13",
  },
]
function Links() {
  return (
    <div className="wrapper">
      <h2 className="header">Links</h2>
      <ul className={`no-list ${styles.links}`}>
        {list.map((item, index) => (
          <li key={`link-${index}`}>
            <small className={typeStyles.metadata}>
              LinkCustom scope="{item.scope}" size="
              {item.appearance}"
            </small>
            <LinkCustom url="/" scope={item.scope} size={item.appearance}>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              ></span>
            </LinkCustom>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Links
