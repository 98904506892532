import React from "react"
import CheckboxCustom from "../CheckboxCustom"
import styles from "./checkboxes.module.scss"

const list = [
  {
    label: "Any need",
    checked: true,
  },
  {
    icon: "rent",
    label: "Rent",
  },
  {
    icon: "groceries",
    label: "Groceries",
  },
  {
    icon: "bills",
    label: "Bills",
  },
  {
    icon: "childcare",
    label: "Childcare",
  },
]

function Checkboxes() {
  return (
    <div className="wrapper">
      <h2 className="header">Checkboxes</h2>
      <ul className={`no-list ${styles.checkboxes}`}>
        {list.map((item, index) => (
          <li key={`checkbox-${index}`}>
            <CheckboxCustom
              checked={!!item.checked}
              icon={item.icon}
              handleClick={() => console.log("click!")}
              label={item.label}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Checkboxes
