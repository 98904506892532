import React from "react"
import styles from "./colors.module.scss"

function Colors() {
  return (
    <div className="wrapper">
      <h2 className="header">Colors</h2>
      <ul className={styles.swatches}>
        <li data-meta="--color-navy">
          <small className={styles.metadata}>var(--color-navy)</small>
        </li>
        <li data-meta="--color-cobalt">
          <small className={styles.metadata}>var(--color-cobalt)</small>
        </li>
        <li data-meta="--color-lavender">
          <small className={styles.metadata}>var(--color-lavender)</small>
        </li>
        <li data-meta="--color-lilac">
          <small className={styles.metadata}>var(--color-lilac)</small>
        </li>
        <li data-meta="--color-peach">
          <small className={styles.metadata}>var(--color-peach)</small>
        </li>
      </ul>
      <ul className={styles.swatches}>
        <li data-meta="--color-fire">
          <small className={styles.metadata}>var(--color-fire)</small>
        </li>
        <li data-meta="--color-lemon">
          <small className={styles.metadata}>var(--color-lemon)</small>
        </li>
        <li data-meta="--color-vanilla">
          <small className={styles.metadata}>var(--color-vanilla)</small>
        </li>
        <li data-meta="--color-cream">
          <small className={styles.metadata}>var(--color-cream)</small>
        </li>
        <li data-meta="--color-white">
          <small className={styles.metadata}>var(--color-white)</small>
        </li>
        <li data-meta="--color-beige">
          <small className={styles.metadata}>var(--color-beige)</small>
        </li>
      </ul>
    </div>
  )
}

export default Colors
