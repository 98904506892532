import React, { useState } from "react"
import styles from "./styles.module.scss"

const CheckboxCustom = ({ checked, label, handleClick }) => {
  const [isChecked, setIsChecked] = useState(false)
  // random string to associate aria-labelledby and id
  const labelledby =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)

  const clickHandler = event => {
    setIsChecked(!isChecked)
    handleClick && handleClick(event)
  }

  return (
    <>
      <button
        aria-checked={isChecked}
        aria-labelledby={labelledby}
        className={styles.checkbox}
        checked={isChecked}
        role="checkbox"
        tabIndex="0"
        onClick={clickHandler}
      >
        <label id={labelledby}>{label}</label>
      </button>
    </>
  )
}

CheckboxCustom.defaultProps = {
  checked: false,
}

export default CheckboxCustom
