import React from "react"
import SEO from "../components/SEO"
import Icon from "../components/Icon"
import ButtonCustom from "../components/ButtonCustom"
import FamilySuggestions from "../components/FamilySuggestions"

import { navigate } from "gatsby"

import styles from "../styles/modules/static-pages.module.scss"

const PageNotFound = () => (
  <>
    <SEO title="404" description="Page not found" />
    <div className={`wrapper wrapper--medium ${styles.notFound}`}>
      <Icon aesthetic="generic" icon="404" />
      <h2 className="x-large-body-copy">
        Sorry, we can’t find the page you’re looking for.
      </h2>
      <ButtonCustom
        size="large"
        hierarchy="primary"
        role="button"
        handleClick={() => navigate("/")}
      >
        Back to home
      </ButtonCustom>
    </div>
    <FamilySuggestions title="Meet neighbors in need" />
  </>
)

export default PageNotFound
