import React from "react"
import Buttons from "./buttons"
import Checkboxes from "./checkboxes"
import Colors from "./colors"
import Icons from "./icons"
import Links from "./links"
import Typography from "./typography"

const styleSection = {
  marginBottom: "10rem",
  textAlign: "center",
}

const Styleguide = () => (
  <main>
    <h1 className="large-header text-center">Styleguide</h1>

    <section style={styleSection}>
      <Colors />
    </section>
    <section style={styleSection}>
      <Typography />
    </section>
    <section style={styleSection}>
      <h2 className="header">UI Elements</h2>
      <Links />
      <Buttons />
      <Checkboxes />
    </section>
    <section style={styleSection}>
      <Icons />
    </section>
  </main>
)

export default Styleguide
