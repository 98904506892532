import React, { useState } from "react"
import SEO from "../components/SEO"
import FamilySuggestions from "../components/FamilySuggestions"
import ButtonCustom from "../components/ButtonCustom"
import { handleExploreNeeds } from "../utils"
import styles from "../styles/modules/static-pages.module.scss"
import Modal, { ModalType } from "../components/Modal"

const AboutUs = () => {
  const [generalFundOpen, setGeneralFundOpen] = useState(false)

  return (
    <>
      <Modal
        modalType={ModalType.GeneralFund}
        modalOpen={generalFundOpen}
        handleModalClose={() => setGeneralFundOpen(false)}
      />
      <SEO title="About us" description="Different stories, shared community" />
      <div className={`wrapper wrapper--left ${styles.aboutUs}`}>
        <h2 className={`x-large-body-copy ${styles.aboutUsTitle}`}>
          NeighborShare partners with community heroes at local nonprofits to
          identify pivotal needs that would otherwise go unmet and get resources
          directly to families when other options have run out.
        </h2>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          1 in 3 of American families can't afford a $400 emergency.
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          We believe most people would lend a hand to a neighbor in crisis but
          identifying those real-time needs is next to impossible for the
          average citizen.
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          NeighborShare provides a simple, but impactful, solution that fills a
          critical gap in the ecosystem. We partner with community heroes— case
          workers, social workers, etc. who are on the ground in communities —to
          identify pivotal needs of $400 or less that would otherwise go unmet…
          needs that are otherwise slipping through the cracks and sending
          families into preventable crises.
        </p>
        <br></br>
        <h2 className={`x-large-body-copy ${styles.aboutUsTitle}`}>
          When local options have run out, we use our platform to connect those
          critical needs with people who want to help.
        </h2>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          NeighborShare is how those case workers, teachers, healthcare
          professionals, and other community leaders can connect with donors
          ready to make a difference for households in need, and how donors can
          know that the need has been verified and the money is being directed
          by an on-the-ground expert who is optimizing its impact.
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          Donors can give with confidence, knowing their contributions will go
          directly to real households with real needs.
        </p>
        <br></br>
        <h2 className={`x-large-body-copy ${styles.aboutUsTitle}`}>
          Creating Real Impact
        </h2>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          NeighborShare was started in 2020 by a{" "}
          <a href="./team">dedicated team of volunteers</a> and has made
          significant impact since its inception.
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          <a
            href={
              "https://storage.googleapis.com/ns-assets-pd/reports/NeighborShare%20Impact%20Report%20Fall%202023.pdf"
            }
            target="_blank"
          >
            Read our full Impact Report here.
          </a>
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          <a
            href={
              "https://storage.googleapis.com/ns-assets-pd/reports/NeighborShare%20FY22%20Form%20990.pdf"
            }
            target="_blank"
          >
            View our FY 2022 990 here.
          </a>
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          <a
            href={
              "https://storage.googleapis.com/ns-assets-pd/reports/NeighborShare%20FY21%20Form%20990.pdf"
            }
            target="_blank"
          >
            View our FY 2021 990 here.
          </a>
        </p>
        <br></br>
        <div
          className={styles.aboutUsCenterButtons}
          styles="justify-content: center; align-items: center;"
        >
          <ButtonCustom
            hierarchy="secondary"
            handleClick={handleExploreNeeds}
            size="large"
            aboutUsTitle="Meet Households"
          >
            Meet Households
          </ButtonCustom>
        </div>
        <br></br>
        <div className={styles.aboutUsCenterButtons}>
          <ButtonCustom
            hierarchy="secondary"
            handleClick={() => setGeneralFundOpen(true)}
            size="large"
            title="Support The Fund"
          >
            Support The Fund
          </ButtonCustom>
        </div>
        <br></br>
        <p className={`body-copy ${styles.mediumParagraph}`}></p>
        <h2 className={`x-large-body-copy ${styles.aboutUsTitle}`}>
          Interested in getting involved?{" "}
          <a href="mailto:info@nbshare.org">Send us a note!</a>
        </h2>
      </div>
      <FamilySuggestions title="Meet households in need" />
    </>
  )
}

export default AboutUs
