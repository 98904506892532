import React from "react"
import ButtonCustom from "../ButtonCustom"
import typeStyles from "./typography.module.scss"
import styles from "./buttons.module.scss"

const list = [
  {
    size: "large",
    hierarchy: "primary",
    content: "Poppins SemiBold 18 White",
    role: "button",
  },
  {
    size: "medium",
    hierarchy: "primary",
    content: "Poppins SemiBold 14 White",
    role: "button",
  },
  {
    size: "large",
    hierarchy: "secondary",
    content: "Poppins SemiBold 18 Navy",
    role: "button",
  },
  {
    size: "medium",
    hierarchy: "secondary",
    content: "Poppins SemiBold 14 Navy",
    role: "button",
  },
  {
    size: "small",
    content: "Poppins SemiBold 13 White",
    role: "button",
  },
]

function Buttons() {
  return (
    <div className="wrapper">
      <h2 className="header">Buttons</h2>
      <ul className={`no-list ${styles.buttons}`}>
        {list.map((item, index) => (
          <li key={`button-${index}`}>
            <small className={typeStyles.metadata}>
              size="{item.size}" <br />
              {item.hierarchy
                ? `hierarchy="
              ${item.hierarchy}"`
                : null}
              <br />
              role="{item.role}"
            </small>
            <ButtonCustom
              hierarchy={item.hierarchy}
              handleClick={() => console.log("click!")}
              size={item.size}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: item.content,
                  role: "button",
                }}
              ></span>
            </ButtonCustom>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Buttons
