import React from "react"
import typeStyles from "./typography.module.scss"

function Typography() {
  return (
    <div className="wrapper">
      <h2 className="header">Typography</h2>
      <p className={`x-large-body-copy ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>x-large-body-copy</small>
        Taviraj Regulay
      </p>
      <p className={`large-header ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>large-header</small>
        Poppins Black
      </p>
      <p className={`large-body-copy ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>large-body-copy</small>
        Taviraj Regular
      </p>
      <p className={`header ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>header</small>
        Poppins Black
      </p>
      <p className={`body-copy ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>body-copy</small>
        Taviraj Regular
      </p>
      <p className={`small-header ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>small-header</small>
        Poppins Black
      </p>
      <p className={`small-body-copy ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>small-body-copy</small>
        Taviraj Regular
      </p>
      <p className={`tiny-body-copy ${typeStyles.typeItem}`}>
        <small className={typeStyles.metadata}>tiny-body-copy</small>
        Poppins Medium
      </p>
    </div>
  )
}

export default Typography
