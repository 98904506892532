import React from "react"
import Icon from "../Icon"
import styles from "./icons.module.scss"

const icons = {
  minimal: [
    "housing",
    "unclassified",
    "childcare",
    "groceries",
    "transport",
    "bills",
    "education",
  ],
  simple: ["housing", "unclassified", "search", "money"],
  complex: [
    "housing",
    "unclassified",
    "childcare",
    "groceries",
    "transport",
    "bills",
    "education",
  ],
}
function Icons() {
  return (
    <div className="wrapper">
      <h2 className="header">Icons</h2>
      <ul className={`no-list list-horizontal ${styles.iconList}`}>
        {icons.minimal.map((i, index) => (
          <li className={styles.iconWrap} key={`minimal-${index}`}>
            <small className="metadata">
              aesthetic="minimal"
              <br />
              icon="{i}"
            </small>
            <Icon aesthetic="minimal" icon={i} />
          </li>
        ))}
      </ul>
      <ul className={`no-list list-horizontal ${styles.iconList}`}>
        {icons.simple.map((i, index) => (
          <li className={styles.iconWrap} key={`simple-${index}`}>
            <small className="metadata">
              aesthetic="simple"
              <br />
              icon="{i}"
            </small>
            <Icon aesthetic="simple" icon={i} />
          </li>
        ))}
      </ul>
      <ul className={`no-list list-horizontal ${styles.iconList}`}>
        {icons.complex.map((i, index) => (
          <li className={styles.iconWrap} key={`complex-${index}`}>
            <small className="metadata">
              aesthetic="minimal"
              <br />
              icon="{i}"
            </small>
            <Icon aesthetic="complex" icon={i} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Icons
